import { render, staticRenderFns } from "./Active.MainHeader.vue?vue&type=template&id=04d9ffa6"
import script from "./Active.MainHeader.vue?vue&type=script&lang=js"
export * from "./Active.MainHeader.vue?vue&type=script&lang=js"
import style0 from "./Active.MainHeader.vue?vue&type=style&index=0&id=04d9ffa6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../buildchain/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports