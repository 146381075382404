import { render, staticRenderFns } from "./Active.FadeInWrapper.vue?vue&type=template&id=70c04ecc&scoped=true"
import script from "./Active.FadeInWrapper.vue?vue&type=script&lang=js"
export * from "./Active.FadeInWrapper.vue?vue&type=script&lang=js"
import style0 from "./Active.FadeInWrapper.vue?vue&type=style&index=0&id=70c04ecc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../buildchain/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c04ecc",
  null
  
)

export default component.exports