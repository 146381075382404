import 'slick-carousel';
var arrow_right = '<svg id="arrow_right" class="icon" viewBox="0 0 20 20" x="0" y="0"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M0.5 10L18.8 10"/><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-miterlimit="10" d="M14.8 6L18.8 10 14.8 14"/></svg>';
var arrow_left= '<svg id="arrow_left" class="icon" viewBox="0 0 20 20" x="0" y="0"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M19.6,10H1.4"/><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-miterlimit="10" d="M5.3,14l-4-4l4-4"/></svg>';
var bp_sm = '800';
$(document).ready(function() {
  initGalleries();
});

var gallery_instances = [];

var initGalleries = function() {

  var gallery = $('.gallery-container');

  if ( gallery.length ) {
    gallery.each(function(){
      gallery_instances.push(initGallery($(this)));
    })

  }
}
var destroyGalleries = function() {
  var instance;
  while (gallery_instances.length) {
    instance = gallery_instances.shift();
    if (instance == undefined) { return; }
    instance.destroy();
  }
}


var initGallery = function(container) {

  var gallery = {

    initialized: false,
    container: null,
    slider: null,
    slider_thumbs: null,
    speed: 800,

    updateCount: function(index) {
      var _this = this;

      var i = ('0' + (index+1)).slice(-2);

      _this.container.find('.slider-count .current').html(i);

    },

    draw: function(){
      var _this = this;

      _this.el =  _this.slider.slick({
        infinite: true,
        autoplay:false,
        fade: true,
        speed: _this.speed,
        autoplaySpeed: 3000,
        dots: false,
        arrows: false,
        draggable: false,
        pauseOnHover: true,
        asNavFor: '.gallery-slider-nav'
      });

      _this.thumbs = _this.sliderThumbs.slick({
        infinite: true,
        autoplay:false,
        speed: _this.speed,
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.gallery-slider-for',
        dots: false,
        arrows: true,
        // appendArrows: container.find('.slider-arrows'),
        prevArrow: '<button type="button" class="slider-prev btn-style-none" aria-label="Previous">'+arrow_left+'</button>',
        nextArrow: '<button type="button" class="slider-next btn-style-none" aria-label="Next">'+arrow_right+'</button>',
        centerMode: false,
        focusOnSelect: true,
        draggable: true,
        vertical: true,
        verticalSwiping: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              vertical: false,
              verticalSwiping: false,
              draggable: true,
            }
          }
        ]
      })
    },

    destroy: function() {
      var _this = this;
      if ( !_this.el ) return;
      _this.el.slick('unslick');
      _this.initialized = false;
    },

    reset: function() {
      var _this = this;
      _this.destroy();
      _this.draw();
    },
    init: function(container) {
      var _this = this;

      if (container === undefined || container.length < 1) return;

      _this.container = container;
      // _this.options.autoplay = container.attr('data-autoplay');
      // _this.options.appendDots = container.find('.slider-dots');
      _this.slider = _this.container.find('.slider');
      _this.sliderThumbs = _this.container.find('.slider-thumbs');

      if (_this.slider.length < 1) return;

      // === Events
        _this.slider.on('init', function (slick) {
        _this.initialized = true;
        _this.container.removeClass('loading');
        });
      _this.slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        _this.updateCount(nextSlide);
      })
      _this.slider.on('afterChange', function(event, slick, currentSlide) {
      })

      var slides = _this.slider.html();

      _this.draw();

      window.galleryModal.init();

      // on click on slide
      _this.slider.find('.item').on('click', function(e){

        //fade in modal
        window.galleryModal.show();

        var id = $(this).parents('.slick-slide').index();

        // Add gallery to modal and go to slide
        window.galleryModal.showGallery(slides, id, _this.slider);
      })

      // $(window).on('resize', function () {

      //   if ( ! _this.initialized ) {
      //     _this.draw();
      //   }
      // })

      return _this;
    }
  }
  return gallery.init(container);
}

