import Vue from "vue";
import VueMq from "vue-mq";
import Vuex from "vuex";
import VueResource from "vue-resource";
import VueRellax from "vue-rellax";
import VueScrollReveal from "vue-scroll-reveal";
import { VueHammer } from "vue2-hammer";

import utils from "../utils";

const breakpoints = {
  xs: 620,
  sm: 800,
  md: 1220,
  lg: 1500,
  xl: Infinity,
};

Vue.mixin(utils);

Vue.use(VueMq, { breakpoints, defaultBreakpoint: "xs" });
Vue.use(VueRellax);
Vue.use(Vuex);
Vue.use(VueHammer);
// Vue.use(VueScrollmagic)
Vue.use(VueResource);
Vue.use(VueScrollReveal, {
  class: "v-scroll-reveal", // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  duration: 800,
  delay: 250,
  scale: 1,
  distance: "50px",
  mobile: false,
});
Vue.directive("vpshow", {
  inViewport(el) {
    var rect = el.getBoundingClientRect();
    return !(
      rect.bottom < 0 ||
      rect.right < 0 ||
      rect.left > window.innerWidth ||
      rect.top > window.innerHeight
    );
  },

  bind(el, binding) {
    el.classList.add("before-enter");
    el.$onScroll = function () {
      if (binding.def.inViewport(el)) {
        el.classList.add("enter");
        el.classList.remove("before-enter");
        binding.def.unbind(el, binding);
      }
    };
    document.addEventListener("scroll", el.$onScroll);
  },

  inserted(el, binding) {
    el.$onScroll();
  },

  unbind(el, binding) {
    document.removeEventListener("scroll", el.$onScroll);
    delete el.$onScroll;
  },
});

// import VueScrollmagic from 'vue-scrollmagic'

// Pull in all of the vue components defined in resources/js/components
const components = require.context("", true, /^((?!Active\.).)*$/i, "lazy");
components.keys().forEach((path) => {
  const componentName = path.match(/\w+(?=(\.vue))/);
  if (componentName) {
    Vue.component(componentName[0], () =>
      import(/* webpackPreload: true */ `${path}`)
    );
  }
});

const componentsFirstRender = require.context("", true, /.*Active\..*/i);
componentsFirstRender.keys().forEach((path) => {
  const componentName = path.match(/\w+(?=(\.vue))/);
  if (componentName) {
    Vue.component(componentName[0], () => import(`${path}`));
  }
});
