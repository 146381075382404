var arrow_right = '<svg id="arrow_right" class="icon" viewBox="0 0 20 20" x="0" y="0"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M0.5 10L18.8 10"/><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-miterlimit="10" d="M14.8 6L18.8 10 14.8 14"/></svg>';
var arrow_left= '<svg id="arrow_left" class="icon" viewBox="0 0 20 20" x="0" y="0"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M19.6,10H1.4"/><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-miterlimit="10" d="M5.3,14l-4-4l4-4"/></svg>';
var bp_sm = '800';

// ======== GALLERY MODAL
window.galleryModal = {
  show: function() {
      if($('#wp-body-div.single-property').length) {
          var propertyInfo = $('.modal-gallery-property-info');
          propertyInfo.find('.listing-id').text($('.listing-overview').attr('data-listing-id'));
          propertyInfo.find('.brokered-by').text($('.listing-overview').attr('data-brokered'));
          propertyInfo.show();
      }

      $('#gallery_modal').modal('show');
  },
  hide: function() {
      var _this = this;
      $('.modal-gallery-property-info').hide();
      $('#gallery_modal').modal('hide');

      // destroy
      _this.destroy();
  },
  goTo: function(slider, index) {
      var _this = this;
      var currentSlide = slider.slick('slickCurrentSlide');

      slider.slick('slickGoTo',index);

      // play video
      var slide = _this.gallery.find('.slick-slide:not(.slick-cloned)').eq(index);
      if (slide.hasClass('video')) {
        slide.find('video')[0].play();
      }

      slider.css('opacity', 1);
  },
  setLabel: function(slide) {
      var _this = this;
      var countContainer = _this.galleryThumbsContainer.siblings('.count-container').find('.slider-count');
      var slideCount = _this.galleryThumbsContainer.find('.slick-slide:not(.slick-cloned)').length;

      // x of x
      if ( countContainer.length ) {
          countContainer.find('.current').html(('0' + (slide+1)).slice(-2));
          countContainer.find('.total').html(('0' + slideCount).slice(-2));
      }
  },
  showGallery: function(slides, slide, navFor, sliderObject){
      var _this = this;

      _this.sliderObject = sliderObject || null;
      _this.heroGallery = navFor;
      _this.createGallery(slides, slide, navFor);
  },
  createGallery: function(slides, slide_index, navFor){
    var _this  = this;
    // append slides
    // slides.removeClass().data('autoplay',false).addClass('gallery');
    _this.galleryContainer.append('<div class="gallery">' + slides + '</div>');

    // video iframe.
    _this.galleryContainer.find('.item.video').each(function() {
      var src = $(this).attr('data-video-src');
      var video = '<video class="lazy" width="800" controls><source src="'+src+'" type="video/mp4"></source /></video>';
      $(this).html(video);
    })

    // thumbs
    _this.galleryThumbsContainer.append('<div class="gallery_thumbs">' + slides + '</div>');
    _this.gallery = _this.galleryContainer.find('.gallery');
    _this.thumbs = _this.galleryThumbsContainer.find('.gallery_thumbs');

    // use bg img
    if ( _this.thumbs.find('.bg-img-cover').length < 1 ) {
      _this.thumbs.find('.img-container').each(function(){
        var inner = $(this);
        var imgUrl = inner.find('img').attr('data-fullImage');
        inner.css('backgroundImage', 'url("'+imgUrl+'")');
        inner.addClass('bg-img-cover');
      })
    }

    // events
    _this.gallery.on('init', function (slick) {
      _this.el.find('.slider-loader').fadeOut();
    });

    // draw slider
    _this.gallery.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      dots: false,
      arrows: false,
      lazyLoad: 'ondemand',
      fade: true,
      asNavFor: _this.thumbs,
      rows: 0
    });
    // events
    _this.thumbs.on('init', function (slick) {
      _this.el.find('.slider-loader').fadeOut();
      _this.setLabel(0);
    });
    _this.gallery.on('beforeChange', function(event, slick, currentSlide, nextSlide) {

      var prevSlide = _this.gallery.find('.slick-slide:not(.slick-cloned)').eq(currentSlide);
      var nextSlide = _this.gallery.find('.slick-slide:not(.slick-cloned)').eq(nextSlide);

      // pause previous video
      if (prevSlide.hasClass('video')) {
        prevSlide.find('video')[0].pause();
      }
      // play next video
      if (nextSlide.hasClass('video')) {
        nextSlide.find('video')[0].play();
      }
    })
    _this.thumbs.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      // give label to first slide
      _this.setLabel(nextSlide);
    })
    _this.thumbs.on('afterChange', function(event, slick, currentSlide) {
      // move slide in gallery (out of modal) if is slick slider
      if ( _this.heroGallery && _this.heroGallery.hasClass('slick-initialized')) {
        _this.heroGallery.slick('slickGoTo',currentSlide);
      }
    })

    var width_item, padding, paddings, nbSlidesToShow, nbSlidesToShowTablet, thumbsContainerWidth;

    // draw slider
    _this.thumbs.slick({
      // slidesToShow: nbSlidesToShow,
      focusOnSelect: true,
      slidesToScroll: 1,
      autoplay: false,
      dots: false,
      arrows: true,
      variableWidth: true,
      lazyLoad: 'progressive',
      centerMode: true,
      centerPadding: '0', // no slide visible on the side
      rows: 0,
      asNavFor: _this.gallery,
      appendArrows: _this.galleryThumbsContainer.parents().find('#slider-modal-arrows'),
      prevArrow: '<button type="button" class="slider-prev btn-style-none" alt="Go to previous slide">' + arrow_left + '</button>',
      nextArrow: '<button type="button" class="slider-next btn-style-none" alt="Go to next slide">' + arrow_right + '</button>',
      responsive: [
      {
        breakpoint: 1199,
        settings: {
        // slidesToShow: nbSlidesToShowTablet
        }
      }
      ]
    });

    _this.goTo(_this.gallery, slide_index);

  },
  destroy: function() {
    var _this = this;
    if ( _this.gallery ) {
      _this.gallery.slick('unslick');
      _this.gallery.remove();
    }
    if ( _this.thumbs ) {
      _this.thumbs.slick('unslick');
      _this.thumbs.remove();
    }
    if ( _this.closeEl) {
      _this.closeEl.css('display','block');
    }

    // remove events
    if ( $('#gallery_modal').length > 0 ) {
      $('#gallery_modal').unbind();
    }

  },
  init: function() {
    var _this = this;

    _this.el = $('#gallery_modal');
    _this.closeEl = $('#close_gallery');
    _this.galleryContainer = _this.el.find('.gallery_container');
    _this.galleryThumbsContainer = _this.el.find('.gallery_thumbs_container');
    _this.galleryArrowsContainer = _this.el.find('#slider-modal-arrows');

    _this.closeEl.on('click',function(){
      _this.hide();
    })

    _this.el.on('click',function(e){
      // if click on overlay, hide modal
      if ( $(e.target).hasClass('img')) {
        _this.hide();
      }
    })

    // $('#gallery_modal').on('show.bs.modal', function() {
    $(document).on('show.bs.modal', '#gallery_modal', function() {
      $('body').addClass('no-scroll');
    })
    // $('#gallery_modal').on('shown.bs.modal', function() {
    $(document).on('shown.bs.modal', '#gallery_modal', function() {
      setTimeout(function(){
        $('#gallery_modal').addClass('shown');
      }, 100)
    })
    // $('#gallery_modal').on('hide.bs.modal', function() {
    $(document).on('hide.bs.modal', '#gallery_modal', function() {
      $('#gallery_modal').removeClass('shown');

      // start progress bar if slider has one (out of modal)
      if ( _this.sliderObject && _this.sliderObject.hasOwnProperty('startProgressBar') ) {
        _this.sliderObject.startProgressBar();
      }

      _this.destroy();

    })
    // $('#gallery_modal').on('hidden.bs.modal', function() {
    $(document).on('hidden.bs.modal', '#gallery_modal', function() {
      $('body').removeClass('no-scroll');
    })


  }
}
