import "./animations";
import "./fixedHeader";
import "./gallery";
import "./galleryModal";
import "./infinite-slider";
import "./lazysizes.min";
import "./modernizr";
import "./sitePopup";
import "./video";
import waypoints from "./waypoints";

export default () => {
    // any class initialisations go here
    waypoints();
}
