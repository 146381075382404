try {
  window.$ = window.jQuery = require('jquery');
  window.slick = require('slick-carousel');
  window.flickity = require('flickity/dist/flickity.pkgd.js');

  require("@babel/polyfill");
  require('objectFitPolyfill');
  require('bootstrap');
  require("slick-carousel/slick/slick.css");
  require("slick-carousel/slick/slick-theme.css");
  require("slick-carousel/slick/ajax-loader.gif");
  require("flickity/css/flickity.css");
} catch (e) {}

/*---- Spinner / Page loader ------*/
var onDocReady = function () {
  document.body.classList.remove("no-js");
};

if (
  document.readyState === "complete" ||
  (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  onDocReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocReady);
}

/*---- STORE CS, CSS, CA URL PARAMS IN COOKIE ------*/

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

/*---- GET CS, CSS, CA FROM URL FOR PARDOT ------*/

function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS, "i");
  var results = regex.exec(window.location.href);
  if (results == null) return "";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
}

/*---- SET COOKIES ----*/

if (getParameterByName("cs") !== "") {
  setCookie("cs", getParameterByName("cs"), 30);
}
if (getParameterByName("css") !== "") {
  setCookie("css", getParameterByName("css"), 30);
}
if (getParameterByName("ca") !== "") {
  setCookie("ca", getParameterByName("ca"), 30);
}

/*---- SET HIDDEN FORM VALUES ------*/

if (document.getElementById("cs") !== null) {
  //document.getElementById("form_cs").value = getParameterByName("cs"); // if only need to check 1 url
  document.getElementById("cs").value = getCookie("cs");
}
if (document.getElementById("css") !== null) {
  //document.getElementById("form_css").value = getParameterByName("css");
  document.getElementById("css").value = getCookie("css");
}
if (document.getElementById("ca") !== null) {
  //document.getElementById("form_ca").value = getParameterByName("ca");
  document.getElementById("ca").value = getCookie("ca");
}

// Add spinner to form on submit
// Note: will only work on forms in the DOM on page load. Need to run this.formHandleSubmit function for forms inside vue components
var forms = document.getElementsByTagName("FORM");
for (var i = 0; i < forms.length; i++) {
  let form = forms[i];
  form.addEventListener("submit", function (e) {
    e.submitter.classList.add("loading");
  });
}
