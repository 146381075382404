document.addEventListener('DOMContentLoaded', function(event) {
  var header = document.getElementById('main-header');
  var lastScrollTop = 0;
  updateHeader();
  window.addEventListener("scroll", function(){
    updateHeader();
  }, false);

  function updateHeader() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      // going down
      if (st > lastScrollTop){
        header.classList.remove('up');
      }
      // going up
      else {
        header.classList.add('up');
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      
    // if (header.classList.contains('has-submneu')) {
    // }

    // Change header style if 5px from top (hide secondary nav, etc)
    if ( window.pageYOffset > 5 ) {
      header.classList.add('min');
      document.body.classList.add('header-min');
    } else {
      header.classList.remove('min');
      header.classList.remove('up');
      document.body.classList.remove('header-min');
    }
  }
})
