// Vimeo API - Froogaloop
var Froogaloop = (function(){
    // Define a local copy of Froogaloop
    function Froogaloop(iframe) {
        // The Froogaloop object is actually just the init constructor
        return new Froogaloop.fn.init(iframe);
    }

    var eventCallbacks = {},
        hasWindowEvent = false,
        isReady = false,
        slice = Array.prototype.slice,
        playerDomain = '';

    Froogaloop.fn = Froogaloop.prototype = {
        element: null,

        init: function(iframe) {
            if (typeof iframe === "string") {
                iframe = document.getElementById(iframe);
            }

            this.element = iframe;

            // Register message event listeners
            playerDomain = getDomainFromUrl(this.element.getAttribute('src'));

            return this;
        },

        /*
         * Calls a function to act upon the player.
         *
         * @param {string} method The name of the Javascript API method to call. Eg: 'play'.
         * @param {Array|Function} valueOrCallback params Array of parameters to pass when calling an API method
         *                                or callback function when the method returns a value.
         */
        api: function(method, valueOrCallback) {
            if (!this.element || !method) {
                return false;
            }

            var self = this,
                element = self.element,
                target_id = element.id !== '' ? element.id : null,
                params = !isFunction(valueOrCallback) ? valueOrCallback : null,
                callback = isFunction(valueOrCallback) ? valueOrCallback : null;

            // Store the callback for get functions
            if (callback) {
                storeCallback(method, callback, target_id);
            }

            postMessage(method, params, element);
            return self;
        },

        /*
         * Registers an event listener and a callback function that gets called when the event fires.
         *
         * @param eventName (String): Name of the event to listen for.
         * @param callback (Function): Function that should be called when the event fires.
         */
        addEvent: function(eventName, callback) {
            if (!this.element) {
                return false;
            }

            var self = this,
                element = self.element,
                target_id = element.id !== '' ? element.id : null;


            storeCallback(eventName, callback, target_id);

            // The ready event is not registered via postMessage. It fires regardless.
            if (eventName != 'ready') {
                postMessage('addEventListener', eventName, element);
            }
            else if (eventName == 'ready' && isReady) {
                callback.call(null, target_id);
            }

            return self;
        },

        /*
         * Unregisters an event listener that gets called when the event fires.
         *
         * @param eventName (String): Name of the event to stop listening for.
         */
        removeEvent: function(eventName) {
            if (!this.element) {
                return false;
            }

            var self = this,
                element = self.element,
                target_id = element.id !== '' ? element.id : null,
                removed = removeCallback(eventName, target_id);

            // The ready event is not registered
            if (eventName != 'ready' && removed) {
                postMessage('removeEventListener', eventName, element);
            }
        }
    };

    /**
     * Handles posting a message to the parent window.
     *
     * @param method (String): name of the method to call inside the player. For api calls
     * this is the name of the api method (api_play or api_pause) while for events this method
     * is api_addEventListener.
     * @param params (Object or Array): List of parameters to submit to the method. Can be either
     * a single param or an array list of parameters.
     * @param target (HTMLElement): Target iframe to post the message to.
     */
    function postMessage(method, params, target) {
        if (!target.contentWindow.postMessage) {
            return false;
        }

        var url = target.getAttribute('src').split('?')[0],
            data = JSON.stringify({
                method: method,
                value: params
            });

        if (url.substr(0, 2) === '//') {
            url = window.location.protocol + url;
        }

        target.contentWindow.postMessage(data, url);
    }

    /**
     * Event that fires whenever the window receives a message from its parent
     * via window.postMessage.
     */
    function onMessageReceived(event) {
        var data, method;

        try {
            data = JSON.parse(event.data);
            method = data.event || data.method;
        }
        catch(e)  {
            //fail silently... like a ninja!
        }

        if (method == 'ready' && !isReady) {
            isReady = true;
        }

        // Handles messages from moogaloop only
        if (event.origin != playerDomain) {
            return false;
        }

        var value = data.value,
            eventData = data.data,
            target_id = target_id === '' ? null : data.player_id,

            callback = getCallback(method, target_id),
            params = [];

        if (!callback) {
            return false;
        }

        if (value !== undefined) {
            params.push(value);
        }

        if (eventData) {
            params.push(eventData);
        }

        if (target_id) {
            params.push(target_id);
        }

        return params.length > 0 ? callback.apply(null, params) : callback.call();
    }


    /**
     * Stores submitted callbacks for each iframe being tracked and each
     * event for that iframe.
     *
     * @param eventName (String): Name of the event. Eg. api_onPlay
     * @param callback (Function): Function that should get executed when the
     * event is fired.
     * @param target_id (String) [Optional]: If handling more than one iframe then
     * it stores the different callbacks for different iframes based on the iframe's
     * id.
     */
    function storeCallback(eventName, callback, target_id) {
        if (target_id) {
            if (!eventCallbacks[target_id]) {
                eventCallbacks[target_id] = {};
            }
            eventCallbacks[target_id][eventName] = callback;
        }
        else {
            eventCallbacks[eventName] = callback;
        }
    }

    /**
     * Retrieves stored callbacks.
     */
    function getCallback(eventName, target_id) {
        if (target_id) {
            return eventCallbacks[target_id][eventName];
        }
        else {
            return eventCallbacks[eventName];
        }
    }

    function removeCallback(eventName, target_id) {
        if (target_id && eventCallbacks[target_id]) {
            if (!eventCallbacks[target_id][eventName]) {
                return false;
            }
            eventCallbacks[target_id][eventName] = null;
        }
        else {
            if (!eventCallbacks[eventName]) {
                return false;
            }
            eventCallbacks[eventName] = null;
        }

        return true;
    }

    /**
     * Returns a domain's root domain.
     * Eg. returns http://vimeo.com when http://vimeo.com/channels is sbumitted
     *
     * @param url (String): Url to test against.
     * @return url (String): Root domain of submitted url
     */
    function getDomainFromUrl(url) {
        if (url.substr(0, 2) === '//') {
            url = window.location.protocol + url;
        }

        var url_pieces = url.split('/'),
            domain_str = '';

        for(var i = 0, length = url_pieces.length; i < length; i++) {
            if(i<3) {domain_str += url_pieces[i];}
            else {break;}
            if(i<2) {domain_str += '/';}
        }

        return domain_str;
    }

    function isFunction(obj) {
        return !!(obj && obj.constructor && obj.call && obj.apply);
    }

    function isArray(obj) {
        return toString.call(obj) === '[object Array]';
    }

    // Give the init function the Froogaloop prototype for later instantiation
    Froogaloop.fn.init.prototype = Froogaloop.fn;

    // Listens for the message event.
    // W3C
    if (window.addEventListener) {
        window.addEventListener('message', onMessageReceived, false);
    }
    // IE
    else {
        window.attachEvent('onmessage', onMessageReceived);
    }

    // Expose froogaloop to the global object
    return (window.Froogaloop = window.$f = Froogaloop);

})();

var close_icon = '<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" d="M16.5 3.5l-13 13M16.5 16.5l-13-13"/></svg>';

$(document).ready(function() {

  // === Responsive iframes and html video
  responsiveVideos();

  // === Play video on click on cover image (+ play fullscreen if applicable)
  playVideoOnCoverClick();

  // === Play video fullscreen 
  playSimpleVideoFullscreen();

})


var responsiveVideos = function() {
  // Find all YouTube and Vimeo videos
  var $allVideos = $("iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com'], figure video:not([data-object-fit])"),
      // The element that is fluid width
      $fluidEl;

  // Figure out and save aspect ratio for each video
  $allVideos.each(function() {

    if ($(this).attr('data-object-fit') || $(this).hasClass('no-resize') ) { return; } // own resize function

    // $(this).wrap('<div class="video-container"></div>');
    // $fluidEl = $(this).closest('.video-container');
    
    $fluidEl = $(this).parent('figure').addClass('video-container');

    if ( $(this).parents('.stacker-section.collapse').length > 0) {
      return;
    }


    var width = this.width;
    var height = this.height;
    if (this.width == 0 ) { width = $(this).width(); }
    if (this.height == 0 ) { height = $(this).height(); }

    $(this)
      .data('aspectRatio', height / width)
      // and remove the hard coded width/height
      .removeAttr('height')
      .removeAttr('width')
      .css('margin','0 auto');

    // Add playsinline to prevent video opening fullscreen on ios
    if ($(this).is('video')) { 
      $(this).attr('playsinline', '');
    }
    else if (!$fluidEl.hasClass('video-with-cover')) {
      if ($(this).attr('src').match(/youtube.com/ig)) { 
        var url = $(this).attr('src');
        $(this).attr('src',url+'?playsinline=1');
      }
    }
  });

  // When the window is resized
  $(window).resize(function() {
    // Resize all videos according to their own aspect ratio
    $allVideos.each(function() {
      var $el = $(this);
      var newWidth = $el.parent('figure').width();
      var newHeight = newWidth * $el.data('aspectRatio');

      // if video taller than window
      if ( newHeight > $(window).height() ) {
        newHeight = $(window).height() - 200;
        newWidth = newHeight / $el.data('aspectRatio');
      }

      $el
        .width(newWidth)
        .height(newHeight)
        .css({
          'opacity': 1,
          'visibility': 'visible'
        });

    });

  // Kick off one resize to fix all videos on page load
  }).resize();
}


var playVideoOnCoverClick = function() {

  if ( $('figure.video-with-cover').length > 0 ) {
    $('figure.video-with-cover .video-cover').on('click', function() {
      var $figure = $(this).parent('figure');
      var $video = null;
      var $cover = $(this);

      if ( $figure.find('iframe').length) {
        $video = $figure.find('iframe');
        $video.attr('allow','autoplay; fullscreen');
        // Open popup and play video
        if ( $figure.hasClass('video-open-fullscreen') ) {
          openVideoFullscreen($video);
        } 
        // Play video inline
        else {
          // Youtube
          if ($video.attr('src').match(/youtube.com/ig)) {
            var url = $video.attr('src');
            // $video.attr('src',url+'?autoplay=1');
            $video.attr('src',url+'?autoplay=1&playsinline=1');
            $cover.fadeOut();
          } 
          // Vimeo: play using froogaloop
          else if ($video.attr('src').match(/vimeo.com/ig)) {
            var player = $f($video[0]);
            player.api("play");
            $cover.fadeOut();
          }
        }

      } else if ( $figure.find('video').length) {
        $video = $figure.find('video');
        var video = $video[0];
        // Open popup and play video
        if ( $figure.hasClass('video-open-fullscreen') ) {
          openVideoFullscreen($video);
        } 
        // Play video inline
        else {
          video.play();
          $cover.fadeOut();
        }
      }

    })
  }
}

var playSimpleVideoFullscreen = function() {
  $('figure:not(".video-with-cover").video-open-fullscreen').each(function() {
    var $figure = $(this);

    if ( $figure.find('iframe').length) {
      // $video = $figure.find('iframe');
      // $video.attr('allow','autoplay; fullscreen');
      // $video.on('click', function() {
      //   openVideoFullscreen($(this));
      // })

    } else if ( $figure.find('video').length) {
      $video = $figure.find('video');
      video = $video[0];
      $video.on('play',function() {
        var video = $(this)[0];
        video.pause();
        openVideoFullscreen($video);
      })
    }

  })
}

// $video can be iframe or html video
var resizePopupVideo = function($video) {
  // remove default width/height
  $video
    .removeAttr('height')
    .removeAttr('width')
    .css('margin','0 auto');

  if ( $video.length < 1 ) { return; }

  var container = $video.parent('.video-container');

  var containerHeight = container.height();
  var newWidth = container.width();

  // Resize video according to its aspect ratio
  var newHeight = newWidth * $video.data('aspectRatio');

  // if video taller than window
  if ( newHeight > containerHeight ) {
    newHeight = containerHeight;
    newWidth = newHeight / $video.data('aspectRatio');
  }

  // set new width and height
  $video
    .width(newWidth)
    .height(newHeight);

  $video.removeClass('is-hidden');
}

var openVideoFullscreen = function($video) {
  if ( $('video-popup').length > 0 ) { $('.video-popup').remove(); }
  var popupMarkup = '<div class="video-popup"><div class="close">'+close_icon+'</div><div class="inner"><figure class="video-container"></figure></div></div>';

  // Create popup with video
  $('body').append(popupMarkup);
  var $popup = $(".video-popup");
  $popup.find('.video-container').append($video.clone());

  var $video = $video.is('iframe') ? $popup.find('iframe') : $popup.find('video');

  // Save ratio and resize video
  $video.data('aspectRatio', $video.height() / $video.width());
  resizePopupVideo($video);

  // show popup
  $popup.addClass('on'); 
  $('body').css('overflow-Y','hidden');

  // play video 
  if ( $video.is('iframe')) {
    $video.attr('allow','autoplay; fullscreen');

    // Youtube
    if ($video.attr('src').match(/youtube.com/ig)) {
      var url = $video.attr('src');
      // $video.attr('src',url+'?autoplay=1');
      $video.attr('src',url+'?autoplay=1&playsinline=1');
    } 
    // Vimeo: play using froogaloop
    else if ($video.attr('src').match(/vimeo.com/ig)) {
      $video.on('load', function() {
        var player = $f($video[0]);
        player.api("play");
      })
    }
  } 
  else {
    $video[0].play();
  }

  // Resize video on window resize
  $(window).resize(function () { 
    resizePopupVideo($video);
  })


  // close poup on click on icon
  $popup.find('.close').off('click').on("click", function(e) { 
    e.stopPropagation();
    e.preventDefault();
    closeVideoPopup($popup);
  });
  // close popup on press escape
  $(document).keyup(function(e) {
    e.stopPropagation();
    e.preventDefault();
    if (e.keyCode == 27) { // escape key maps to keycode `27`
      closeVideoPopup($popup);
    }
  })
}

var closeVideoPopup = function ($popup) {
  $popup.removeClass('on'); 
  $('body').css('overflow-Y','');
  $popup.remove();
}






