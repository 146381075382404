require('waypoints/lib/noframework.waypoints.min');

document.addEventListener('lazyloaded', function(e){
  $img = $(e.target);
  if ( $img.hasClass('project-hero-img')) {
    $img.parent('.img-wrapper').removeClass('loading');
    setTimeout(function() {
      animateComponents.animateProjectHero();
    }, 500)
  }
});


$(document).ready(function() {
  animateComponents.init();
  lazySlideInEls();
});


var animateComponents = {
  parallaxOffsetExponent: 0.1,
  parallaxSpeed: .5,

  animateProjectHero: function() {
    var _this = this;

    var speed = .6;
    var transition_easing = 'Power4.easeOut';

    // Copy
    var timeline = new TimelineMax();
    timeline
      .to($('.project-hero .project-hero-heading'), speed, {autoAlpha: '1', y:'0', ease: transition_easing})
      .to($('.project-hero .project-hero-subheading'), speed, {autoAlpha: '1', y:'0', ease: transition_easing, onComplete: handleComplete}, '+=.2');

  
    function handleComplete() {
      if ( Modernizr.mq("screen and (max-width:1200px)") ) { return; }
      var $arc = $('.project-hero .arc-wrapper');
      _this.parallax($arc);
      setTimeout(function() {
        $arc.addClass('parallaxed');
      }, _this.parallaxSpeed * 1000)
    }
  },

  animateParallax: function(e) {

    // if in scroll event, "this" is given as param
    var _this = e == null ? this : e.data.obj;

    // no parallax on mobile
    if ( Modernizr.mq("screen and (max-width:1200px)") ) { return; }

    var items = $("[data-parallax]"),
        scrollY = window.scrollY;

    $(items).each(function() {
      _this.parallax($(this));
    })

  },

  parallax: function($item) {
    var _this = this;
    var height = $item.outerHeight(),
        unit = $item.attr("data-parallax"),
        offsetExponent = $item.attr('data-parallax-offset');
    
    var top = $item.offset().top - $(window).scrollTop(),
        offset = top * - unit * offsetExponent;
    TweenLite.to($item, _this.parallaxSpeed, {
      y: offset
    })
  },

  handleScroll: function(e) {
    e.preventDefault();
    var _this = e.data.obj;

    // Animate on scroll
    _this.animateParallax();
  },
  init: function() {

    var _this = this;

    // Parallax
    var parallaxItems = $("[data-parallax]");
    if ( parallaxItems.length > 0 ) {
      parallaxItems.each(function() {

        var $item = $(this);

        // add offset 
        var offsetAttr = $item.attr('data-parallax-offset');
        if ( typeof offsetAttr == typeof undefined || offsetAttr == false ) {
          $item.attr('data-parallax-offset',_this.parallaxOffsetExponent);
        }

        // parallax now
        // if ( $item.hasClass('parallax-on-load')) {
        //   _this.parallax($item);
        //   setTimeout(function() {
        //     $item.addClass('parallaxed');
        //   }, _this.parallaxSpeed * 1000)
        // }

      });

      $(window).on('scroll', {obj: _this}, _this.handleScroll); // passing _this to method
    }

  }, 

  destroy: function() {

  }
}


// Slide in Effect (Vanilla JS)

var lazySlideInEls = function() {
  var slideInEls = [].slice.call(document.querySelectorAll(".slide-in, .lazy-slide-in"));

  var offset = '-10%';

  var bottom = '-100px';
  if ( window.matchMedia("(max-width: 1800px)").matches ) {
    bottom = '-50px';
  }

  var options = {
    // rootMargin: offset
    rootMargin: '0px 0px '+bottom+' 0px', // x px above bottom of window
  };

  if ("IntersectionObserver" in window) {
    var slideInObserver = new IntersectionObserver(function(entries, observer) {

      entries.forEach(function(item) {
        if (item.isIntersecting) {
          item.target.classList.add("sliding-in");
          setTimeout(function() { 
            item.target.classList.remove("sliding-in"); 
            item.target.classList.add("slided-in"); 
          }, 200);
          slideInObserver.unobserve(item.target);
        }
      });
    },options);

    slideInEls.forEach(function(lazyVideo) {
      slideInObserver.observe(lazyVideo);
    });
  }
  // End Slide in effect
}