import svgIcons from "srcPath/assets/svg/icon.svg";

const utils = {
  methods: {
    /**
     * Get an icon from the `icon.svg` file.
     * @param {string} icon - The icon name.
     * @returns - A full URL of the versioned icon path.
     */
    getMixIcon(icon) {
      if (process.env.NODE_ENV === "development") {
        return `/dist/img/icon.svg#${icon}`;
      }
      return `${svgIcons}#${icon}`;
    },

    /**
     * Create a random ID.
     * @param {number} length - The length of the ID.
     * @returns {string} - The randomly generated ID.
     */
    makeId(length) {
      const result = [];
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charLength = characters.length;
      for (let i = 0; i < length; i++) {
        const char = characters.charAt(Math.floor(Math.random() * charLength));
        result.push(char);
      }
      return result.join("");
    },

    formHandleSubmit() {
      var forms = document.getElementsByTagName("FORM");
      for (var i = 0; i < forms.length; i++) {
        let form = forms[i];
        form.addEventListener("submit", function (e) {
          e.submitter.classList.add("loading");
        });
      }
    },

    getUrlParameter(param) {
      let params = new URLSearchParams(window.location.search);
      return params.get(param);
    },

    httpBuildQuery(obj) {
      let parts = [];

      Object.keys(obj).forEach(function (key) {
        if (obj[key]) {
          parts.push(`${key}=${obj[key]}`);
        }
      });

      return parts.length ? "?" + parts.join("&") : "";
    },

    priceFormat(value, lease) {
      return value
        ? "$" +
            parseFloat(value)
              .toFixed()
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            (lease ? "pa" : "")
        : "";
    },
  },
};

export default utils;
