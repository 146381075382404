require("waypoints/lib/noframework.waypoints.min");

// var waypoint = new Waypoint({
//   element: document.getElementById('sohubs-section'),
//   handler: function(direction) {
//     console.log('Scrolled to waypoint v4!')
//   }
// })

export default function () {
  // Home banner scroll symbol
  const bannerScroll = document.getElementById("banner-scroll");
  if (bannerScroll) {
    new Waypoint({
      element: bannerScroll,
      handler: function (direction) {
        if (direction == "down") {
          this.element.classList.add("hover");
        } else {
          this.element.classList.remove("hover");
        }
      },
      offset: function () {
        var offset = window.innerHeight - this.element.clientHeight - 50; // 50px from bottom of viewport
        return offset;
      },
    });
  }
}
